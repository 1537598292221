// CatalogLinks.vue

<template>
  <div class="d-none d-md-block" v-show="catalog.length > 0">
    <div id="catalog-line">
      <div v-for="cat in catalog" :key="cat.id">
        <a :href="cat.url" class="black" >
          <!--<div class="catalog-item fr_reg" :style="{ backgroundColor: '#' + cat.color }">{{ cat.name }}</div>-->
          <div class="catalog-item fr_reg">{{ cat.name }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      catalog: []
    }
  },
  methods: {
    getCatalogLines () {
      //const path = `https://t2www.votonia.ru/promo/catalog-lines/`
      const path = `https://www.votonia.ru/promo/catalog-lines/`
      axios.get(path)
        .then(response => {
          this.catalog = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    this.getCatalogLines()
  }
}
</script>

<style>
#catalog-line {
  margin-top: 1em;
  display: flex !important;
  justify-content: center;
}
#catalog-line a {
  text-decoration: none;
  color: #fff !important;
}
#catalog-line a:hover {
  text-decoration: underline;
  color: #000;
}
#catalog-line div.catalog-item {
  padding: 12px 0;
  margin: 0px 8px;
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 10px;
  width: 135px;
}
#catalog-line>div .fr_reg{
  background-color: #4ebf00;
  color: #fefefe;
  width: 7em !important;
  padding: 0.1em 1em !important;
  font-size: 14px !important;
  font-weight: 900;
}

#catalog-line>div .fr_reg:hover{
  background-color: #fff;
  color: #000;
  width: 7em !important;
  padding: 0.1em 1em !important;
  font-size: 14px !important;
}
</style>
