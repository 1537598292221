import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.use(BootstrapVue)
Vue.use(VueYandexMetrika, {id: 21488785, env: process.env.NODE_ENV})

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
