// FooterBlock.vue

<template>
  <div id="footer">
    <a href="https://www.votonia.ru">
      <div class="fr_bold">
        <span><font-awesome-icon icon="shopping-cart" /></span>
        продолжить покупки на votonia.ru
      </div>
    </a>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faShoppingCart)
export default {
  components: {
    FontAwesomeIcon
  }
}
</script>

<style>
#footer {
  height: 140px;
  margin-top: 10px;
}
#footer a {
  color: #000;
  text-decoration: none;
}
#footer div {
  padding: 12px;
  margin: 0px 10px;
  margin-top: 45px;
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 10px;
  background: #fff;
  /*background: #01ec6c;*/
  display: inline-block;
  font-weight: 500 !important;
}
#footer div:hover {
  text-decoration: underline;
}
#footer span {
  font-size: 24px !important;
  vertical-align: middle;
  margin-right: 5px;
}
@media screen and (max-width: 767px) {
  #footer {
    height: 90px;
  }
  #footer div {
    font-size: 13px;
    margin-top: 10px;
  }
  #footer span {
    font-size: 20px !important;
  }
}
</style>
