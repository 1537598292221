// ProductsRows.vue

<template>
  <div id="products-rows" v-show="products.length > 0">
    <!--<div class="products-rows-title fr_reg">только сегодня!</div>-->
   <!--<div id="only_now_back"><img src="../assets/only_now.png" id="only_now"/></div>-->
    <div class="products-rows-line">
      <div v-for="product in products" :key="product.id" class="products-rows-item">
        <a :href="product.url">
          <!-- <img v-if="product.image" :src="'https://timg.votonia.ru/promo_products/' + product.image" /> -->
          <img v-if="product.image" :src="product.image" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      products: []
    }
  },
  methods: {
    getProducts () {
      //const path = `https://t2www.votonia.ru/promo/products/`
      const path = `https://www.votonia.ru/promo/products/`
      axios.get(path)
        .then(response => {
          this.products = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    this.getProducts()
  }
}
</script>

<style>
#products-rows {
  margin-top:20px;
}
.products-rows-title {
  text-transform: uppercase;
  font-size: 24px;
  padding: 10px 0px;
}
.products-rows-line {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  /*background: #f2f2f2;*/
 background: #ffffff;
  /*background: #00b0f0;*/
  padding: 10px 10px;
}
.products-rows-item img {
  width: 208px;
  height: 262px;
  margin: 10px 15px;
}

#only_now{
  width:20em;
}



@media screen and (max-width: 1023px) {
  .products-rows-item img {
    width: 156px;
    height: 196.5px;
  }  
}
@media screen and (max-width: 423px) {
  .products-rows-item img {
    width: 140px;
    height: 176px;
    margin: 10px 5px;
  }
  #only_now{
    width:10em;
  }
}
@media screen and (max-width: 767px) {
  #only_now{
    width:10em;
  }
}
</style>
