<template>
  <div id="app">
    <back-to-site></back-to-site>
    <div v-if="starting">
      <header-block :day="day" :duration="duration"></header-block>
      <countdown-block :deadline="deadline"></countdown-block>
      <catalog-links></catalog-links>
      <catalog-rows></catalog-rows>
      <products-rows></products-rows>
      <brands-block></brands-block>
    </div>
    <div v-else>
      <not-starting-block></not-starting-block>
    </div>
    <our-advantages></our-advantages>
    
  </div>
</template>

<script>
import BackToSite from './components/BackToSite.vue'
import NotStartingBlock from './components/NotStartingBlock.vue'
import HeaderBlock from './components/HeaderBlock.vue'
import CountdownBlock from './components/CountdownBlock.vue'
import CatalogLinks from './components/CatalogLinks.vue'
import CatalogRows from './components/CatalogRows.vue'
import ProductsRows from './components/ProductsRows.vue'
import BrandsBlock from './components/BrandsBlock.vue'
import OurAdvantages from './components/OurAdvantages.vue'
import FooterBlock from './components/FooterBlock.vue'
import axios from 'axios'
export default {
  name: 'App',
  components: {
    BackToSite,
    NotStartingBlock,
    HeaderBlock,
    CountdownBlock,
    CatalogLinks,
    CatalogRows,
    ProductsRows,
    BrandsBlock,
    OurAdvantages,
    FooterBlock
  },
  data () {
    return {
      deadline: '1970-01-01 00:00:00',
      starttime: '1970-01-01 00:00:00',
      duration: 3,
      starting: false,
      days: ['день', 'дня', 'дней'],
      day: 'дня'
    }
  },
  methods: {
    getDeadline () {
      //const path = `https://t2www.votonia.ru/promo/deadline/`
      const path = `https://www.votonia.ru/promo/deadline/`

      axios.get(path)
        .then(response => {
          this.deadline = response.data.deadline
          this.starttime = response.data.starttime
          this.duration = response.data.duration
          this.starting = response.data.starting
          this.day = this.getStringByNum()
        })
        .catch(error => {
          console.log(error)
        })
    },
    getStringByNum () {
      var n = Math.abs(this.duration) % 100
      var n1 = n % 10
      if (n > 10 && n < 20) { return this.days[2] }
      if (n1 > 1 && n1 < 5) { return this.days[1] }
      if (n1 === 1) { return this.days[0] }
      return this.days[2]
    }
  },
  created () {
    this.getDeadline()
  }
}
</script>

<style>
@import './assets/css/styles.css';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 10px;
}
</style>
