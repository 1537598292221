// CatalogRows.vue

<template>
  <div id="catalog-rows" v-show="catalog.length > 0">
    <div class="catalog-rows-line">
      <div v-for="cat in catalog" :key="cat.id" class="catalog-rows-item">
        <a :href="cat.url">
          <!-- <img v-if="cat.image" :src="'https://timg.votonia.ru/promo_catalog/' + cat.image" /> -->
          <img v-if="cat.image" :src="cat.image" />
        </a>
      </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      window: {
        width: 0,
        height: 0
      },
      catalog_rows_items: 2,
      catalog: []
    }
  },
  methods: {
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    getCatalog () {
      //const path = `https://t2www.votonia.ru/promo/catalog/`
      const path = `https://www.votonia.ru/promo/catalog/`
      axios.get(path)
        .then(response => {
          this.catalog = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.getCatalog()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style>
.catalog-rows-line {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
 /* background: #f2f2f2; */
 background: #ffffff;
  /*background: #00b0f0;**/
  padding: 10px 100px;
  /*margin-top: 35px;*/
}
.catalog-rows-item img {
  width: 213px;
  height: 233px;
  /* border: 2px solid #b3b3b3; */
  margin: 10px 25px;
}

.catalog-rows {
  margin-top: -2.2em;
}

@media screen and (max-width: 1023px) {
  .catalog-rows-line {
      padding: 10px 10px;
  }
  .catalog-rows-item img {
    width: 140px;
    height: 153px;
    margin: 10px 25px;
  }
}
@media screen and (max-width: 423px) {
  .catalog-rows-item img {
    margin: 10px 5px;
  }
}
</style>
