// BrandsBlock.vue

<template>
  <div id="brands-block" >
    <div id="only_now_back"><img src="../assets/only_now.png" id="only_now"/></div>
    <div class="brands-block-title fr_reg" style="color:#fff;font-weight: 900;">участники распродажи</div>
    <div class="brands-block-line">
        <!--<div v-for="brand in brands" :key="brand.id" class="brand-item">
          <a :href="brand.url">-->
            <!-- <img v-if="brand.logo" :src="'https://img.votonia.ru/brands/thumb/' + brand.logo" /> -->
            <!--<img v-if="brand.logo" :src="brand.logo" />-->
          <!--</a>
        </div>-->
        <img src="../assets/salers.png" style="width:24em"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      brands: []
    }
  },
  methods: {
    getBrands () {
      //const path = `https://t2www.votonia.ru/promo/brands/`
      const path = `https://www.votonia.ru/promo/brands/`
      axios.get(path)
        .then(response => {
          this.brands = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    this.getBrands()
  }
}
</script>

<style>
#brands-block {
  /*margin-top:20px;*/
}
.brands-block-title {
  text-transform: uppercase;
  font-size: 24px;
  padding: 10px 0px;
}
.brands-block-line {
  margin-top: 5px;
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
}
.brand-item {
  width: 100px;
  height: 100px;
  padding: 10px;
  /*background: #fff;*/
  float: left;
  margin: 10px 10px;
}
.brand-item img {
  width: 80px !important;
  height: 80px;
}
</style>
