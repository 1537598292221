// HeaderBlock.vue
/* eslint-disable */
<template>
  <div id="head">
  <div id="header">
    <!--<h1 class="black fr_bold"><span class="red">summer sale</span></h1>-->
    <!--<h1 class="black fr_bold"><span class="red">crazy sale</span></h1>-->
    <!--<h1 class="black fr_bold">black friday</h1>-->
    <!--<h1 class="black fr_bold" style="color:#ff2400 !important">happy new sale</h1>-->
    <!--<h2 class="black fr_reg">такие огромные скидки будут только {{ duration }} {{ day }}!</h2>-->
    <!--<h2 class="black fr_reg">АКЦИЯ ЗАКОНЧИТСЯ ЧЕРЕЗ:</h2>-->
    <!--<h2 class="black fr_reg" >скидки до 70%</h2>-->
    <!--<h2 class="black fr_reg" >безумные скидки на детские товары только {{ duration }} {{ day }}!</h2>-->    
  </div>
  </div>
</template>
/* eslint-disable */
<script>
export default {
  props: [
    'duration',
    'day'
  ]
}
</script>

<style>
#header h1 {
  font-size: 80px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
#header h2 {
  font-size: 26px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 20px;
}


@media screen and (max-width: 767px) {
  #header h1 {
    font-size: 48px;
  }
  #header h2 {
    font-size: 10px;
  }
}
</style>
