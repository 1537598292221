// HeaderBlock.vue

<template>
  <div class="sticky">
    <flip-countdown :deadline="deadline"></flip-countdown>
  </div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'
export default {
  components: {
    FlipCountdown
  },
  props: [
    'deadline'
  ]
}
</script>

<style>
@import '../assets/css/flip_countdown.css';
.sticky {
  /*position: sticky;*/
  /*top: 10px;*/
  /*display: none;*/
  margin-top: -7.0em;
}
@media screen and (max-width: 767px) {
   .sticky {
     margin-top: -2.0em;
     margin-bottom: -1.5em;
  }
  #back-to-site{
     display: none;
  }
}
</style>
