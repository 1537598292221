// BackToSite.vue

<template>
  <div id="back-to-site" class="fr_reg">
    <!--<a href="https://www.votonia.ru">
      <span><font-awesome-icon icon="chevron-circle-left" /></span>
      Назад в &laquo;ВотОнЯ&raquo;
    </a>-->
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faChevronCircleLeft)
export default {
  components: {
    FontAwesomeIcon
  }
}
</script>

<style>
#back-to-site {
  position: absolute;
  top: 20px;
  left: 30px;
}
#back-to-site a {
  color: #1184dd;
  font-weight: 900;
  /*color: #ff2400;*/
  text-decoration: none;
  font-size: 16px;
}
#back-to-site a:hover {
  text-decoration: underline;
}
#back-to-site span {
  font-size: 24px !important;
  vertical-align: middle;
  margin-right: 5px;
  /*color: #00B0F0 !important;*/
}

@media screen and (max-width: 767px) {
  #back-to-site {
    left: 10px;
}
  #back-to-site a {
    font-size: 12px;
  }
}
</style>
